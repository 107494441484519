
import { defineComponent } from "vue";

export default defineComponent({
  name: "about-value",
  data() {
    return {
      list1: [
        {
          heading: "Integrity and Transparency :",
          text: "At Halal we value integrity as a core part of our business process by demonstrating sound moral and ethical principles, always doing the right thing above what is considered good, no matter who is watching.",
        },
        {
          heading: "Respect :",
          text: "We extend the human touch to our users beyond mere transactions and aim to build business relationships marked by mutual respect.",
        },
      ],
      list2: [
        {
          heading: "Inclusion :",
          text: "We are committed to equitable treatment and elimination of discrimination in all its forms, while promoting diversity. ",
        },
        {
          heading: "Innovation :",
          text: "We are unsettled by banality, we make sure that our processes and product become better every day by searching for ways to improve.",
        },
      ],
    };
  },
});
