
import Layout from "@/Layout/Layout.vue";
import AboutBox from "@/sections/AboutBox.vue";
import AboutValue from "@/sections/AboutValue.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { Layout, AboutValue, AboutBox },
  name: "About",
  mounted() {
    window.scrollTo({ top: 0 });
  },
});
